/* color */

.color-primary {
  color: #845587 !important;
}

.color-secondary {
  color: #9A739C !important;
}

.color-disabled {
  color: #6A6E6E !important;
}

.color-link {
  color: #59B4E8 !important;
}

.color-danger {
  color: #F45B69 !important;
}

.color-success {
  color: #88D498 !important;
}

.color-warning {
  color: #FCC459 !important;
}

.color-white {
  color: #ffffff !important;
}
