
.design-systems .logo {
  padding: 20px 0 50px 0;
  text-align: center;
}

/* programs */
.design-systems .thq-program-wrapper h1,
.design-systems .thq-program-wrapper h2 {
  text-align: left;
}
.design-systems .thq-program-wrapper h2, .design-systems .thq-program-wrapper h3 {
  padding: 0;
}
.design-systems .thq-program-wrapper .logo {
  text-align: left;
}

.thq-program {
  height: 50px;
}

.thq-program h1 {
  padding: 5px 0 5px 0;
  margin: 0;
  text-align: left;
}

.thq-program a {
  padding: 0;
  margin: 0;
  text-align: left;
}

/* modules */
.thq-module {
  height: 100px;
}

.thq-module h1 {
  padding: 5px 0 5px 0;
  margin: 0;
  text-align: left;
}

.thq-module p {
  padding: 0;
  margin: 0;
}

.thq-module a {
  padding: 0;
  margin: 0;
  text-align: left;
}

/* box */
.design-systems .card {
  border: 1px solid #efefef !important;
}

.design-systems .atom-el-box {
  box-shadow: 0 2px 10px 0 #eee !important;
  transition: 0.3s !important;
  border-radius: 8px !important; /* 5px rounded corners */
}

.design-systems .atom-el-box .card {
  border-radius: 8px !important; /* 5px rounded corners */
}

/* navbar */
.design-systems .navbar-brand {
  margin-left: 8px;
  padding: 8px 0
}

/* landing */
.design-systems .landing {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* landing logo */
.design-systems .landing-logo {
  text-align: center;
  margin: 30px auto;
}

/* landing logo */
.design-systems .landing-logo img {
  width: auto;
  height: 100%;
}
