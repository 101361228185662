body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

#thq-main-body {
  /* padding-left: 2rem !important; */
  padding-top: 4.25rem !important;
  padding-botom: 1.25rem !important;
}

#thq-sidebar-wrapper {
  min-height: 100vh;
  margin-left: -12rem;
  -webkit-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#thq-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper .list-group {
  width: 12rem;
}

#thq-content-wrapper {
  min-width: 100vw;
}

#thq-main-wrapper.toggled #thq-sidebar-wrapper {
  margin-left: 0;
}

/* @media (min-width: 768px) {
  #thq-sidebar-wrapper {
    margin-left: 0;
  }

  #thq-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #thq-main-wrapper.toggled #thq-sidebar-wrapper {
    margin-left: -12rem;
  }
} */

#thq-sidebar-wrapper #thq-sidebar-modules {
  /* padding: 1.25rem 1.25rem; */
  font-size: 1.2rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules p {
  font-size: 1rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules a {
  color: #999999;
  text-decoration: none;
}

#thq-main-wrapper .border-right {
  border-right: 1px solid #efefef !important;
}

.navbar-brand {
  display: inline !important;
}
.toggled .navbar-brand {
  display: none !important;
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url("/assets/westfield/fonts/proxima/ProximaNova-Regular.otf")
    format("opentype");
}
.proxima-nova-regular {
  font-family: "ProximaNovaRegular" !important;
}
.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ef9a9a !important;
  background-image: none !important;
}

.is-invalid:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

button a {
  color: #ffffff !important;
}

button {
  margin-right: 5px !important;
}

/* recaptcha */
#g-recaptcha {
  margin: 10px auto;
  text-align: center;
}
#g-recaptcha > div {
  width: 100% !important;
}
ul {
  list-style-type: none;
  padding: 0;
}

.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(left, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(left, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

.card {
  border: none;
}

.atom-el-box {
  box-shadow: 0 1px 8px 0 #efefef;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* .atom-el-transaction-detail:nth-child(even) {
  background-color: #f9f9f9;
} */

.atom-el-list {
  color: #888888;
}

.atom-el-list-head {
  padding: 5px 0;
}

.atom-el-list-detail {
  padding: 5px 0;
  margin: 5px 10px;
}

/* .atom-el-transaction-detail:nth-child(even) {
  background-color: #f9f9f9;
} */

.atom-el-list {
  color: #888888;
}

.atom-el-list-head {
  padding: 5px 0;
}

.atom-el-list-detail {
  padding: 5px 0;
  margin: 5px 10px;
}

.skeleton-container {
  width: 100%;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(left, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

/* information */
.app-information-style {
  color: #6d7097 !important;
  background-color: #ebedff !important;
  border-color: #ebedff !important;
}
.app-information-style p {
  color: #6d7097 !important;
}

/* success */
.app-success-style {
  color: #8fbda1 !important;
  background-color: #d2f1de !important;
  border-color: #d2f1de !important;
}
.app-success-style p {
  color: #8fbda1 !important;
}

/* fail */
.app-fail-style {
  color: #b787af !important;
  background-color: #ffdcf9 !important;
  border-color: #ffdcf9 !important;
}
.app-fail-style p {
  color: #b787af !important;
}

/* information */
.app-help-style {
  color: #b1b1b1 !important;
  background-color: #fcfcfc !important;
  border-color: #fcfcfc !important;
}
.app-help-style p {
  color: #b1b1b1 !important;
}

.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ffbfc9 !important;
  background-image: none !important;
}

.is-invalid:focus, .form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

/* background */

.background-primary {
  background-color: #845587 !important;
}

.background-secondary {
  background-color: #9A739C !important;
}

.background-disabled {
  background-color: #6A6E6E !important;
}

.background-link {
  background-color: #59B4E8 !important;
}

.background-danger {
  background-color: #F45B69 !important;
}

.background-success {
  background-color: #88D498 !important;
}

.background-warning {
  background-color: #FCC459 !important;
}

/* color */

.color-primary {
  color: #845587 !important;
}

.color-secondary {
  color: #9A739C !important;
}

.color-disabled {
  color: #6A6E6E !important;
}

.color-link {
  color: #59B4E8 !important;
}

.color-danger {
  color: #F45B69 !important;
}

.color-success {
  color: #88D498 !important;
}

.color-warning {
  color: #FCC459 !important;
}

.color-white {
  color: #ffffff !important;
}

/* typography */

.title-1 {
  font-size: 55px;
}

.title-2 {
  font-size: 43px;
}

.title-3 {
  font-size: 35px;
}

.title-4 {
  font-size: 28px;
}

.title-semi {
  font-size: 21px;
}

.title-large {
  font-size: 17px;
}

.title-regular {
  font-size: 14px;
}

.title-small {
  font-size: 11px;
}

.title-mini {
  font-size: 8px;
}

.title-micro {
  font-size: 7px;
}

/* spacing */

.space-micro {
  margin: 2px !important;
  padding: 0 !important
}

.space-micro-top {
  margin-top: 2px !important;
  padding: 0 !important
}

.space-micro-bottom {
  margin-bottom: 2px !important;
  padding: 0 !important
}

.space-tiny {
  margin: 4px !important;
  padding: 0 !important
}

.space-tiny-top {
  margin-top: 4px !important;
  padding: 0 !important
}

.space-tiny-bottom {
  margin-bottom: 4px !important;
  padding: 0 !important
}

.space-tiny-left {
  margin-left: 4px !important;
  padding: 0 !important
}

.space-tiny-right {
  margin-right: 4px !important;
  padding: 0 !important
}

.space-small {
  margin: 8px !important;
  padding: 0 !important
}

.space-small-top {
  margin-top: 8px !important;
  padding: 0 !important
}

.space-small-bottom {
  margin-bottom: 8px !important;
  padding: 0 !important
}

.space-small-left {
  margin-left: 8px !important;
  padding: 0 !important
}

.space-small-right {
  margin-right: 8px !important;
  padding: 0 !important
}

.space-standard {
  margin: 16px !important;
  padding: 0 !important
}

.space-standard-top {
  margin-top: 16px !important;
  padding: 0 !important
}

.space-standard-bottom {
  margin-bottom: 16px !important;
  padding: 0 !important
}

.space-semi {
  margin: 24px !important;
  padding: 0 !important
}

.space-semi-top {
  margin-top: 24px !important;
  padding: 0 !important
}

.space-semi-bottom {
  margin-bottom: 24px !important;
  padding: 0 !important
}

.space-large {
  margin: 32px !important;
  padding: 0 !important
}

.space-x-large {
  margin: 48px !important;
  padding: 0 !important
}

/* button */
/* primary - #845587 */
/* secondary - #9A739C */

.btn-primary {
  color: #fff !important;
  background-color: #00A8E8 !important;
  border-color: #00A8E8 !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff !important;
  background-color: #00A8E8 !important;
  border-color: #00A8E8 !important;
}

.btn-click-able {
  cursor: pointer !important;
  border: 1px solid #fff;
  border-radius: 5px;
}
.btn-click-able:hover {
  border: 1px solid #00A8E8;
}

.btn-primary a {
  color: #fff !important;
}

/* button */
/* danger - #F45B69 */

.alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #fff !important;
  background-color: #F45B69 !important;
  border-color: #F45B69 !important;
}



.design-systems .logo {
  padding: 20px 0 50px 0;
  text-align: center;
}

/* programs */
.design-systems .thq-program-wrapper h1,
.design-systems .thq-program-wrapper h2 {
  text-align: left;
}
.design-systems .thq-program-wrapper h2, .design-systems .thq-program-wrapper h3 {
  padding: 0;
}
.design-systems .thq-program-wrapper .logo {
  text-align: left;
}

.thq-program {
  height: 50px;
}

.thq-program h1 {
  padding: 5px 0 5px 0;
  margin: 0;
  text-align: left;
}

.thq-program a {
  padding: 0;
  margin: 0;
  text-align: left;
}

/* modules */
.thq-module {
  height: 100px;
}

.thq-module h1 {
  padding: 5px 0 5px 0;
  margin: 0;
  text-align: left;
}

.thq-module p {
  padding: 0;
  margin: 0;
}

.thq-module a {
  padding: 0;
  margin: 0;
  text-align: left;
}

/* box */
.design-systems .card {
  border: 1px solid #efefef !important;
}

.design-systems .atom-el-box {
  box-shadow: 0 2px 10px 0 #eee !important;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  border-radius: 8px !important; /* 5px rounded corners */
}

.design-systems .atom-el-box .card {
  border-radius: 8px !important; /* 5px rounded corners */
}

/* navbar */
.design-systems .navbar-brand {
  margin-left: 8px;
  padding: 8px 0
}

/* landing */
.design-systems .landing {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* landing logo */
.design-systems .landing-logo {
  text-align: center;
  margin: 30px auto;
}

/* landing logo */
.design-systems .landing-logo img {
  width: auto;
  height: 100%;
}

.st1 {
  fill: #aa2239;
}

.atom-el-loader {
  background-color: #fff !important;
  height: 1em !important;
  width: 100%;
  margin: 0 auto;
  padding: .25em;
  border-radius: 50px !important;
  box-shadow: 0 2px 5px #eee;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.atom-el-loader .progress-bar {
  background-color: #ddd !important;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

