/* button */
/* danger - #F45B69 */

.alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #fff !important;
  background-color: #F45B69 !important;
  border-color: #F45B69 !important;
}
