/* button */
/* primary - #845587 */
/* secondary - #9A739C */

.btn-primary {
  color: #fff !important;
  background-color: #00A8E8 !important;
  border-color: #00A8E8 !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff !important;
  background-color: #00A8E8 !important;
  border-color: #00A8E8 !important;
}

.btn-click-able {
  cursor: pointer !important;
  border: 1px solid #fff;
  border-radius: 5px;
}
.btn-click-able:hover {
  border: 1px solid #00A8E8;
}

.btn-primary a {
  color: #fff !important;
}
