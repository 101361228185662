/* .atom-el-transaction-detail:nth-child(even) {
  background-color: #f9f9f9;
} */

.atom-el-list {
  color: #888888;
}

.atom-el-list-head {
  padding: 5px 0;
}

.atom-el-list-detail {
  padding: 5px 0;
  margin: 5px 10px;
}
