@font-face {
  font-family: "ProximaNovaRegular";
  src: url("/assets/westfield/fonts/proxima/ProximaNova-Regular.otf")
    format("opentype");
}
.proxima-nova-regular {
  font-family: "ProximaNovaRegular" !important;
}
.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ef9a9a !important;
  background-image: none !important;
}

.is-invalid:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

button a {
  color: #ffffff !important;
}

button {
  margin-right: 5px !important;
}

/* recaptcha */
#g-recaptcha {
  margin: 10px auto;
  text-align: center;
}
#g-recaptcha > div {
  width: 100% !important;
}
ul {
  list-style-type: none;
  padding: 0;
}
