/* information */
.app-information-style {
  color: #6d7097 !important;
  background-color: #ebedff !important;
  border-color: #ebedff !important;
}
.app-information-style p {
  color: #6d7097 !important;
}

/* success */
.app-success-style {
  color: #8fbda1 !important;
  background-color: #d2f1de !important;
  border-color: #d2f1de !important;
}
.app-success-style p {
  color: #8fbda1 !important;
}

/* fail */
.app-fail-style {
  color: #b787af !important;
  background-color: #ffdcf9 !important;
  border-color: #ffdcf9 !important;
}
.app-fail-style p {
  color: #b787af !important;
}

/* information */
.app-help-style {
  color: #b1b1b1 !important;
  background-color: #fcfcfc !important;
  border-color: #fcfcfc !important;
}
.app-help-style p {
  color: #b1b1b1 !important;
}
