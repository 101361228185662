/* spacing */

.space-micro {
  margin: 2px !important;
  padding: 0 !important
}

.space-micro-top {
  margin-top: 2px !important;
  padding: 0 !important
}

.space-micro-bottom {
  margin-bottom: 2px !important;
  padding: 0 !important
}

.space-tiny {
  margin: 4px !important;
  padding: 0 !important
}

.space-tiny-top {
  margin-top: 4px !important;
  padding: 0 !important
}

.space-tiny-bottom {
  margin-bottom: 4px !important;
  padding: 0 !important
}

.space-tiny-left {
  margin-left: 4px !important;
  padding: 0 !important
}

.space-tiny-right {
  margin-right: 4px !important;
  padding: 0 !important
}

.space-small {
  margin: 8px !important;
  padding: 0 !important
}

.space-small-top {
  margin-top: 8px !important;
  padding: 0 !important
}

.space-small-bottom {
  margin-bottom: 8px !important;
  padding: 0 !important
}

.space-small-left {
  margin-left: 8px !important;
  padding: 0 !important
}

.space-small-right {
  margin-right: 8px !important;
  padding: 0 !important
}

.space-standard {
  margin: 16px !important;
  padding: 0 !important
}

.space-standard-top {
  margin-top: 16px !important;
  padding: 0 !important
}

.space-standard-bottom {
  margin-bottom: 16px !important;
  padding: 0 !important
}

.space-semi {
  margin: 24px !important;
  padding: 0 !important
}

.space-semi-top {
  margin-top: 24px !important;
  padding: 0 !important
}

.space-semi-bottom {
  margin-bottom: 24px !important;
  padding: 0 !important
}

.space-large {
  margin: 32px !important;
  padding: 0 !important
}

.space-x-large {
  margin: 48px !important;
  padding: 0 !important
}
