.atom-el-loader {
  background-color: #fff !important;
  height: 1em !important;
  width: 100%;
  margin: 0 auto;
  padding: .25em;
  border-radius: 50px !important;
  box-shadow: 0 2px 5px #eee;
  transition: 0.3s;
}

.atom-el-loader .progress-bar {
  background-color: #ddd !important;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}
