/* background */

.background-primary {
  background-color: #845587 !important;
}

.background-secondary {
  background-color: #9A739C !important;
}

.background-disabled {
  background-color: #6A6E6E !important;
}

.background-link {
  background-color: #59B4E8 !important;
}

.background-danger {
  background-color: #F45B69 !important;
}

.background-success {
  background-color: #88D498 !important;
}

.background-warning {
  background-color: #FCC459 !important;
}
