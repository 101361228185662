/* typography */

.title-1 {
  font-size: 55px;
}

.title-2 {
  font-size: 43px;
}

.title-3 {
  font-size: 35px;
}

.title-4 {
  font-size: 28px;
}

.title-semi {
  font-size: 21px;
}

.title-large {
  font-size: 17px;
}

.title-regular {
  font-size: 14px;
}

.title-small {
  font-size: 11px;
}

.title-mini {
  font-size: 8px;
}

.title-micro {
  font-size: 7px;
}
